import clsx from "clsx";
import type IconProps from "components/atoms/icons/Icon";
import { forwardRef } from "react";
import { DEFAULT_ICON_SIZE } from "util/const";

const DisplayOk = forwardRef<SVGSVGElement, IconProps>(
  (
    { size = DEFAULT_ICON_SIZE, left, right, auto, color, className, ...rest },
    ref,
  ): React.JSX.Element => (
    <svg
      ref={ref}
      className={clsx(
        "ui-svg-inline icon cursor-pointer",
        left && "mr-1",
        right && "ml-1",
        auto && "mx-auto",
        color && `text-${color}`,
        className,
      )}
      viewBox="0 -960 960 960"
      fill="currentColor"
      width={size || "1em"}
      height={size || "1em"}
      {...rest}
    >
      <path d="M140-856.016c-19.15 0-36.101 7.39-50.86 22.149-14.758 14.758-22.148 31.71-22.148 50.86v521.991c0 19.15 7.39 36.101 22.149 50.86 14.758 14.758 31.709 22.148 50.859 22.148h176.016v84.024h327.968v-84.024H820c19.15 0 36.101-7.39 50.86-22.148 14.758-14.759 22.148-31.71 22.148-50.86V-608.71A201.544 201.544 0 0 1 820-566.836v305.82H140v-521.992h418.672a201.544 201.544 0 0 1 23.984-73.008H140z" />
      <path d="M738.365-578.456h-20l20-140h-70c-17.6 0-6.6-15-6.2-15.6 25.8-45.6 64.6-113.6 116.4-204.4h20l-20 140h70.2c8 0 12.4 3.8 8 13.2-79 137.8-118.4 206.8-118.4 206.8Z" />
    </svg>
  ),
);

DisplayOk.displayName = "DisplayOk";
export default DisplayOk;
