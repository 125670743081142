import clsx from "clsx";
import type IconProps from "components/atoms/icons/Icon";
import { forwardRef } from "react";
import { DEFAULT_ICON_SIZE } from "util/const";

const Location = forwardRef<SVGSVGElement, IconProps>(
  (
    { size = DEFAULT_ICON_SIZE, left, right, auto, color, className, ...rest },
    ref,
  ): React.JSX.Element => (
    <svg
      ref={ref}
      aria-label="add-tags"
      className={clsx(
        "icon",
        left && "mr-1",
        right && "ml-1",
        auto && "mx-auto",
        color && `text-${color}`,
        className,
      )}
      viewBox="0 0 24 24"
      fill="currentColor"
      width={size || "1em"}
      height={size || "1em"}
      {...rest}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path
        d="M12 .04203c-4.62056 0-8.37058 3.60025-8.37058 8.03627 0 4.58068 4.88842 11.5883 8.37058 15.87967 3.53238-4.32353 8.37058-11.25078 8.37058-15.87967 0-4.43602-3.75002-8.03627-8.37058-8.03627zm0 12.05441c-2.31028 0-4.18529-1.80013-4.18529-4.01814 0-2.21801 1.87501-4.01813 4.18529-4.01813s4.18529 1.80012 4.18529 4.01813c0 2.21801-1.87501 4.01814-4.18529 4.01814z"
        opacity=".5"
      />
      <circle fill="currentColor" opacity=".1" cx="12" cy="8.0323" r="4.23426" />
    </svg>
  ),
);

Location.displayName = "Location";
export default Location;
