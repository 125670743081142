import clsx from "clsx";
import type IconProps from "components/atoms/icons/Icon";
import { forwardRef } from "react";
import { DEFAULT_ICON_SIZE } from "util/const";

const Samsung = forwardRef<SVGSVGElement, IconProps>(
  (
    { size = DEFAULT_ICON_SIZE, left, right, auto, color, className, ...rest },
    ref,
  ): React.JSX.Element => (
    <svg
      ref={ref}
      className={clsx(
        "icon",
        left && "mr-1",
        right && "ml-1",
        auto && "mx-auto",
        color && `text-${color}`,
        className,
      )}
      fill="currentColor"
      width={size || "1em"}
      height={size || "1em"}
      {...rest}
      viewBox="0 0 512 512"
    >
      <path
        d="M292.588 332.165c3.486 8.609 2.419 19.708 .783 26.396c-2.988 11.811-11.028 23.906 -34.65 23.906c-22.341 0-35.859 -12.807 -35.859 -32.302l0-34.436l-95.482 0l-.071 27.535c0 79.331 62.469 103.309 129.421 103.309c64.39 0 117.396-21.986 125.792 -81.324c4.34-30.736 1.067 -50.872 -.356 -58.485c-15.013-74.493 -150.124 -96.763 -160.157 -138.385c-1.708-7.115 -1.21 -14.728 -.356 -18.783c2.49-11.313 10.246 -23.835 32.515 -23.835c20.776 0 33.084 12.878 33.084 32.302c0 6.546 0 21.985 0 21.985l88.723 0l0-24.973c0-77.553 -69.584 -89.648 -119.958 -89.648c-63.323 0-115.048 20.918 -124.511 78.833c-2.561 16.009-2.917 30.238 .783 48.097c15.582 72.643 141.943 93.704 160.299 139.808z"
        fill="inherit"
      />
    </svg>
  ),
);

Samsung.displayName = "Samsung";
export default Samsung;
