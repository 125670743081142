import clsx from "clsx";
import type IconProps from "components/atoms/icons/Icon";
import { forwardRef } from "react";
import { DEFAULT_ICON_SIZE } from "util/const";

const FolderOpen = forwardRef<SVGSVGElement, IconProps>(
  (
    { size = DEFAULT_ICON_SIZE, left, right, auto, color, className, ...rest },
    ref,
  ): React.JSX.Element => (
    <svg
      ref={ref}
      className={clsx(
        "icon",
        left && "mr-1",
        right && "ml-1",
        auto && "mx-auto",
        color && `text-${color}`,
        className,
      )}
      viewBox="0 -960 960 960"
      fill="currentColor"
      width={size || "1em"}
      height={size || "1em"}
      {...rest}
    >
      <path d="M137-144q-28.363 0-50.681-22.319Q64-188.637 64-217v-526q0-28.362 22.319-50.681T137-816h271l73 73h342q28.362 0 50.681 22.319T896-670H450l-73-73H137v519l97-386h711L844-210q-10 38-31 52t-63 14H137Zm75-73h558l77-320H289l-77 320Zm0 0 77-320-77 320Zm-75-453v-73 73Z" />
    </svg>
  ),
);

FolderOpen.displayName = "FolderOpen";
export default FolderOpen;
