import clsx from "clsx";
import type IconProps from "components/atoms/icons/Icon";
import { forwardRef } from "react";
import { DEFAULT_ICON_SIZE } from "util/const";

const FitScreen = forwardRef<SVGSVGElement, IconProps>(
  (
    { size = DEFAULT_ICON_SIZE, left, right, auto, color, className, ...rest },
    ref,
  ): React.JSX.Element => (
    <svg
      ref={ref}
      className={clsx(
        "ui-svg-inline icon cursor-pointer",
        left && "mr-1",
        right && "ml-1",
        auto && "mx-auto",
        color && `text-${color}`,
        className,
      )}
      viewBox="0 0 24 24"
      fill="currentColor"
      width={size || "1em"}
      height={size || "1em"}
      {...rest}
    >
      <path d="M20 9V6h-3V4h3q.825 0 1.413.588Q22 5.175 22 6v3ZM2 9V6q0-.825.588-1.412Q3.175 4 4 4h3v2H4v3Zm15 11v-2h3v-3h2v3q0 .825-.587 1.413Q20.825 20 20 20ZM4 20q-.825 0-1.412-.587Q2 18.825 2 18v-3h2v3h3v2Zm2-4V8h12v8Zm2-2h8v-4H8Zm0 0v-4 4Z" />
    </svg>
  ),
);

FitScreen.displayName = "FitScreen";
export default FitScreen;
