import clsx from "clsx";
import type IconProps from "components/atoms/icons/Icon";
import { forwardRef } from "react";
import { DEFAULT_ICON_SIZE } from "util/const";

const AngleUp = forwardRef<SVGSVGElement, IconProps>(
  (
    { size = DEFAULT_ICON_SIZE, left, right, auto, color, className, ...rest },
    ref,
  ): React.JSX.Element => (
    <svg
      ref={ref}
      aria-label="add-tags"
      className={clsx(
        "icon",
        left && "mr-1",
        right && "ml-1",
        auto && "mx-auto",
        color && `text-${color}`,
        className,
      )}
      viewBox="0 0 24 24"
      fill="currentColor"
      width={size || "1em"}
      height={size || "1em"}
      {...rest}
    >
      <path d="M16.24268,15.12109a.99676.99676,0,0,1-.707-.293L12,11.293,8.46436,14.82812a.99989.99989,0,0,1-1.41407-1.41406L11.293,9.17188a.99962.99962,0,0,1,1.41406,0l4.24268,4.24218a1,1,0,0,1-.707,1.707Z" />
    </svg>
  ),
);

AngleUp.displayName = "AngleUp";
export default AngleUp;
