import clsx from "clsx";
import type IconProps from "components/atoms/icons/Icon";
import { createElement, forwardRef } from "react";
import { DEFAULT_ICON_SIZE } from "util/const";

const Copy = forwardRef<SVGSVGElement, IconProps>(
  ({ size = DEFAULT_ICON_SIZE, left, right, auto, color, className, ...rest }, ref): React.JSX.Element =>
    createElement(
      "svg",
      Object.assign(
        {},
        {
          ref,
          viewBox: "0 0 24 24",
          width: size || "1em",
          height: size || "1em",
          fill: "currentColor",
          className: clsx(
            "icon",
            left && "mr-1",
            right && "ml-1",
            auto && "mx-auto",
            color && `text-${color}`,
            className,
          ),
          ...rest,
        },
      ),
      createElement("path", {
        d: "M21,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,14.05,2H10A3,3,0,0,0,7,5V6H6A3,3,0,0,0,3,9V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V18h1a3,3,0,0,0,3-3V9S21,9,21,8.94ZM15,5.41,17.59,8H16a1,1,0,0,1-1-1ZM15,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V9A1,1,0,0,1,6,8H7v7a3,3,0,0,0,3,3h5Zm4-4a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1h3V7a3,3,0,0,0,3,3h3Z",
      }),
    ),
);

Copy.displayName = "Copy";
export default Copy;
